import React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout
    headerClassName="bg-navy-p3"
    headerContent={<span />}
    className="accent-none"
    headerTitle="Not found"
  >
    <div className="container text-center my-12">
      <p className="text-2xl text-fuchsia lg:text-3xl mt-3 font-display font-bold">
        That page doesn't exist
      </p>
      <p className="text-xl">
        Please excuse the dust! We recently updated our website.
      </p>
      <p className="mt-3">
        <span className="font-semibold">Having trouble finding something?</span>
        <br />
        Let us know at{" "}
        <a className="text-royal" href="mailto:info@scriptdrop.co">
          info@scriptdrop.co
        </a>
      </p>
    </div>
  </Layout>
);

export default NotFoundPage;
